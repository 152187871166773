import React from 'react'
import { PopupButton } from '@typeform/embed-react'
import { srcSetProps, sanityImageUrl, clickTrack } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ top }) => {
  return (
    <div className={styles.top}>
      <div className={styles.wrap}>
        <img {...srcSetProps(sanityImageUrl(top.image))} className={styles.background} alt={top.image?.caption} />
        <div className={styles.box}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: top.title }} />
          <p className={styles.description}>{top.description}</p>

          <PopupButton
            id={top.quizButtonUrl.replace('https://quiz/', '')}
            as="a"
            transitiveSearchParams={true}
            onReady={() => clickTrack(top.quizButtonText)}
            className={styles.quiz}
          >
            <span dangerouslySetInnerHTML={{ __html: top.quizButtonText }} />
          </PopupButton>

          {top.linkButtonText && top.linkButtonUrl && (
            <div>
              <a className={styles.link} href={top.linkButtonUrl} target="_blank">
                {top.linkButtonText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
