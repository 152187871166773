import React, { useEffect } from 'react'
import { PopupButton } from '@typeform/embed-react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { clickTrack } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ quiz }) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const goToNextQuestion = key => {
    if (key === quiz.items[quiz.items.length - 1]._key) {
      gsap.to(window, {
        duration: 1,
        scrollTo: document.getElementById('quiz-result'),
      })
    } else {
      const questionIndex = quiz.items.findIndex(q => q._key === key)
      gsap.to(window, {
        duration: 1,
        scrollTo: document.getElementById(quiz.items[questionIndex + 1]._key),
      })
    }
  }

  return (
    <div className={styles.quiz}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{quiz.title}</h2>

        <div className={styles.items}>
          {quiz.items &&
            quiz.items.map(s => (
              <div key={s._key} className={styles.item}>
                <div id={s._key} className={styles.anchor} />
                <p className={styles.tag}>{s.tag}</p>
                <p className={styles.question}>{s.title}</p>

                <div className={styles.answers}>
                  {s.questions &&
                    s.questions.map(q => (
                      <div key={q._key} className={styles.answer} onClick={() => goToNextQuestion(s._key)}>
                        {q.text}
                      </div>
                    ))}
                </div>

                <p className={styles.info}>{s.info}</p>
                <p className={styles.source}>{s.source}</p>
              </div>
            ))}
        </div>

        <div className={styles.bottom}>
          <div id="quiz-result" className={styles.anchor} />
          <h2 className={styles.bottomTitle}>{quiz.titleBottom}</h2>
          <PopupButton
            id={quiz.quizButtonUrl.replace('https://quiz/', '')}
            as="a"
            onReady={() => clickTrack(quiz.quizButtonText)}
            transitiveSearchParams={true}
            className={styles.bottomQuiz}
          >
            <span dangerouslySetInnerHTML={{ __html: quiz.quizButtonText }} />
          </PopupButton>
          <p className={styles.bottomText}>{quiz.textBottom}</p>
        </div>
      </div>
    </div>
  )
}
